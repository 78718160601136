<template>
    <div>
        <b-row>
            <b-alert v-model="showSuccessAlert" variant="success" dismissible>
                {{ alertMessage }}
            </b-alert>
        </b-row>
        <b-row class="text-center">
            <b-col>
                <div class="filter-card p-3"
                     :class="{
                        'bg-active-filter': totalIsActive,
                        'bg-light': !totalIsActive,
                      }"
                     @click="totalUserIsActiveFilter">
                    <h6>Utenti totali</h6>
                    <h4>
                        <strong>{{ numberOfUsers }}</strong>
                    </h4>
                </div>
            </b-col>
            <b-col>
                <div class="filter-card p-3"
                     :class="{'bg-active-filter': activeIsActive,'bg-light': !activeIsActive,}"
                     @click="activeUserIsActiveFilter">
                    <h6 class="text-secondary">Utenti attivi</h6>
                    <h4>
                        <strong>{{ activeUsers }}</strong>
                    </h4>
                </div>
            </b-col>

        </b-row>
        <b-row class="mt-3">
            <b-card>
                <b-row align-h="between">
                    <b-col cols="6">
                        <h3>{{ tableHeader }}</h3>
                    </b-col>
                    <b-col cols="2">
                        <b-row>
                            <b-col>
                                <b-button variant="primary"
                                          id="show-btn"
                                          @click="showCreateModal">
                                    <b-icon-plus class="text-white"></b-icon-plus>
                                    <span class="h6 text-white">Nuovo Utente</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-table striped
                             hover
                             :items="items"
                             :fields="fields"
                             class="text-center">
                        <template #cell(Userid)="data">
                            {{
                                `${data.item.userId}`
                            }}
                        </template>
                        <template #cell(Email)="data">
                            {{
                                `${data.item.email}`
                            }}
                        </template>
                        <template #cell(Name)="data">
                            {{
                                `${data.item.name}`
                            }}
                        </template>
                        <template #cell(Surname)="data">
                            {{
                                `${data.item.surname}`
                            }}
                        </template>
                        <template #cell(Role)="data">
                            {{
                                `${data.item.role}`
                            }}
                        </template>
                       
                        <template #cell(Deleted)="data">
                            <b-icon-bookmark-check-fill variant="success"
                                                        v-if="data.item.deleted != true"></b-icon-bookmark-check-fill>
                            <b-icon-bookmark-x-fill variant="danger"
                                                    v-else></b-icon-bookmark-x-fill>
                        </template>
                        <template #cell(actions)="data">
                            <b-row>
                                <b-col cols="7">
                                    <b-icon-pencil-square class="action-item"
                                                          variant="primary"
                                                          @click="getRowData(data.item.userId)"></b-icon-pencil-square>
                                </b-col>
                                <b-col cols="1">
                                    <b-icon-trash-fill class="action-item"
                                                       variant="danger"
                                                       @click="showDeleteModal(data.item.userId)"></b-icon-trash-fill>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                </b-row>
            </b-card>
        </b-row>

        <!-- Modal for adding new users -->
        <b-modal ref="create-user-bo-modal"
                 size="xl"
                 hide-footer
                 title="Aggiungi utente">
            <create-user-bo-form @closeCreateModal="closeCreateModal"
                                  @reloadDataTable="getUserData"
                                  @showSuccessAlert="showAlertCreate"></create-user-bo-form>
        </b-modal>

        <!-- Modal for updating users -->
        <b-modal ref="edit-user-bo-modal"
                 size="xl"
                 hide-footer
                 title="Modifica Utente">
            <edit-user-bo-form @closeEditModal="closeEditModal"
                                @reloadDataTable="getUserData"
                                @showSuccessAlert="showAlertUpdate"
                                :userId="userId"></edit-user-bo-form>
        </b-modal>

        <!-- Delete User Modal -->
        <b-modal ref="delete-user-bo-modal"
                 size="md"
                 hide-footer
                 title="Conferma cancellazione">
            <delete-user-bo-modal @closeDeleteModal="closeDeleteModal"
                                   @reloadDataTable="getUserData"
                                   @showDeleteAlert="showDeleteSuccessModal"
                                   :userId="userId"></delete-user-bo-modal>
        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";
/*    import UserBoOverview from "@/components/UsersBo/UserBoOverview.vue";*/
    import CreateUserBoForm from "@/components/Users/CreateUserBoForm.vue";
    import EditUserBoForm from "@/components/Users/EditUserBoForm.vue";
    import DeleteUserBoModal from "@/components/Users/DeleteUserBoModal.vue";

export default {
  components: {
/*    UserBoOverview,*/
    CreateUserBoForm,
    EditUserBoForm,
    DeleteUserBoModal,
  },
  data() {
    return {
      // Note 'isActive' is left out and will not appear in the rendered table

      fields: [
        {
          key: "Userid",
          label: "Id",
          sortable: false,
            },
        {
            key: "Email",
            label: "Email",
          sortable: false,
        },
        {
            key: "Name",
          label: "Nome",
          sortable: false,
        },
        {
            key: "Surname",
            label: "Cognome",
            sortable: false,
        },
        {
            key: "Role",
            label: "Ruolo",
            sortable: false,
        },           
        {
          key: "Deleted",
          label: "Attivo",
          sortable: false,
        },
        "actions",
      ],
      items: [],
      numberOfUsers: 0,
      activeUsers: 0,
      activeUsersData: [],
      userId: 0,
      companySearchTerm: "",
      tableHeader: "",
      showSuccessAlert: false,
        alertMessage: "",
        rowsInPage: 20,
        filterValue: "",
        totalIsActive: false,
        activeIsActive: true,
    };
  },
  mounted() {
      this.getUserData();
      this.activeUserIsActiveFilter();
      
        },
  computed: {
      
  },
  methods: {
    showCreateModal() {
      this.$refs["create-user-bo-modal"].show();
      },
      totalUserIsActiveFilter() {
          this.totalIsActive = true;
          this.activeIsActive = false;
          this.getUserData();
      },
      activeUserIsActiveFilter() {
          this.totalIsActive = false;
          this.activeIsActive = true;
          this.items = this.activeUsersData;
      },

    closeCreateModal() {
      this.$refs["create-user-bo-modal"].hide();
    },
      getUserData(e) {
          if (e != undefined && e.target.value != null)
              this.rowsInPage = e.target.value;
          const params = {
              numPages: 0,
              totalPages: 0,
              rowsInPage: 20,
              columnFilter: "",
              filterValue: "",
              columOrder: "",
              isAsc: true
          };
         /* console.log(params);*/
          this.$store.commit("ENABLE_LOADING"); 

      axios
          .get("/api/UsersBoes/GetUserBo", { params })
        .then((response) => {
            
        //   console.log(response);
            this.items = response.data.usersBo;
            this.numberOfUsers = response.data.usersBo.length;
            this.tableHeader = this.numberOfUsers + " Utenti BackOffice";
            this.activeUsersData = response.data.usersBo.filter(
                (item) => item.deleted !== true
            );
            this.activeUsers = this.activeUsersData.length;

            if (this.activeIsActive) this.items = this.activeUsersData;
            
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
               if (error.response.status === 401) this.logout();
            }
        });
          this.$store.commit("DISABLE_LOADING");
      },
      logout() {
          localStorage.removeItem('token');
          this.$store.dispatch('user', null);
          this.$router.push('/');     
      },
      getRowData(id) {
      this.userId = id;
      this.$refs["edit-user-bo-modal"].show();
      
    },
    closeEditModal() {
      this.$refs["edit-user-bo-modal"].hide();
    },
    setFilterTotalIsActive() {

      this.getUserData();
    },
    setFilterActiveIsActive() {

      this.items = this.activeUsersData;
    },
    showAlertCreate() {
      this.showSuccessAlert = true;
      this.alertMessage = "User creato correttamente!";
    },
    showAlertUpdate() {
      this.showSuccessAlert = true;
      this.alertMessage = "User aggiornato correttamente";
    },
      showDeleteModal(id) {
          this.userId = id;
      this.$refs["delete-user-bo-modal"].show();
      
    },
    closeDeleteModal() {
      this.$refs["delete-user-bo-modal"].hide();
    },
    showDeleteSuccessModal() {
      this.showSuccessAlert = true;
      this.alertMessage = "User cancellato!";
    },
  },
};
</script>

<style>
    .action-item:hover {
        cursor: pointer;
    }
</style>