<template>
    <div class="mt-3">
        <h3 v-if="user">Benvenuto, {{ user.name }} {{ user.surname }}</h3>
        <!--<Spinner />-->

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    /*import Spinner from 'spinner';  */  

    export default {
        name: "Home",
        computed: {
            ...mapGetters(['user'])
        }
        /*,components: {  Spinner }*/
    }
</script>

<style scoped>
</style>
