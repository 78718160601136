<template>
    <div>
        <b-row class="mt-2 mb-3">
            <h6 class="text-secondary">
                Si sta cancellando l&rsquo;utente, con questa operazione l&rsquo;utente non potr&agrave; pi&ugrave; accedere al portale.
            </h6>
        </b-row>
        <b-row class="mt-2 mb-3">
            <p class="text-danger">
                Questa operazione &egrave; irreversibile, causer&agrave; la perdita di tutti i dati dell&rsquo;utente.'
            </p>
        </b-row>
        <b-row class="mt-6">
            <b-col>
                <b-button variant="danger" @click="removeUserFromData">Elimina utente</b-button>
            </b-col>
            <b-col>
                <b-button variant="warning" @click="triggerClose">Chiudi</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DeleteUserModal",
  props: {
    userId:null,
  },
  methods: {
    triggerClose() {
      this.$emit("closeDeleteModal");
    },
    removeUserFromData() {
      axios
          .post(`/api/UsersBoes/DeleteUserBo/${this.userId}`)
        .then(() => {
          this.$emit("reloadDataTable");
          this.$emit("showDeleteAlert");
          this.$emit("closeDeleteModal");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>