<template>
    <div class="form-signin">
        <b-row>
            <b-alert v-model="showSuccessAlert" variant="success" dismissible>
                {{ alertMessage }}
            </b-alert>
        </b-row>
        <div id="app" :class="[isLoading ? 'loading' : '']">
            <div class="card">
                <div class="card-body">


                    <form @submit.prevent="handleSubmit">

                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" v-model="form.email">
                            <!--<p v-if="!emailIsValid" class="error-message">Mettere una mail valida.</p>-->
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" v-model="form.password">
                            <!--<p v-if="!passwordIsValid" class="error-message">Mattere una passord di almeno 8 caratteri.</p>-->
                        </div>
                        <button type="submit" :disabled="!formIsValid" class="btn btn-primary w-100">Login</button>

                        <!--<p class="forgot-password text-center">
                            <router-link to="forgot">Password dimenticata?</router-link>
                        </p>-->


                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import { mapGetters } from "vuex";

    export default {
        name: "Login",
        data() {
            return {
                form: this.initForm(),
                showSuccessAlert: false,
                alertMessage : ""
            }
        },
        computed: {
            emailIsValid() {
                if (!this.form.email) {
                    return false;
                } else if (!this.validEmail(this.form.email)) {
                    return false;
                } else {
                    return true;
                }
            },
            passwordIsValid() {
                return (!!this.form.password) || (this.form.password.lenght > 4);
            },
            formIsValid() {
                return this.emailIsValid && this.passwordIsValid
            },
            ...mapGetters(["isLoading"])


        },

        methods: {
            async handleSubmit() {

                this.$store.commit("ENABLE_LOADING");
                try {

                    await axios.post('api/Account/Login', {
                        UserName: this.form.email,
                        email: this.form.email,
                        password: this.form.password,
                        

                    }).then((response) => {
                        var token = response.data.authenticationToken;
                        localStorage.setItem('token', token);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        /*console.log(localStorage.getItem('token'));*/
                        this.$store.dispatch('user', response.data.user);
                        this.$router.push('/');
                    }).catch((error) => {
                        
                    //    console.log(error.response);
                        if (error.response) {
                            this.showAlertError(error.response.data.error);
                        }
                    });

                //} catch (e) {
                //    console.log(e);
                //    this.showAlertError(e.error);
                //    /*this.errors.push('Utente o password errati!')*/
                } finally {
                    this.$store.commit("DISABLE_LOADING");
                }

            },
            showAlertError(e) {
                this.showSuccessAlert = true;
                this.alertMessage = "Errore autenticazione: " +e;
            },



            initForm() {
                return {
                    email: '',
                    password: '',
                    errors: [],
                    passwordIsValid: true,
                    emailIsValid: true,
                    formIsValid: true,
                    alertMessage: "",
                    showSuccessAlert: false
                }
            },
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }
    }
</script>

<style type="text/css">
    #app.loading,
    #app.loading * {
        cursor: wait;
    }
</style>