import axios from 'axios';

//axios.defaults.baseURL = 'https://localhost:7018/';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.defaults.baseURL = 'https://api.serman.cloud/';

/*axios.defaults.baseURL = process.env.VUE_APP_BASEURL;//'__baseURL__';*/


axios.interceptors.request.use(function (config) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    return config;
}, function (error) {
   
    return Promise.reject(error);
});

