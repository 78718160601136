<template>
    <form @submit.prevent="handleSubmit">
        <h3>Password dimenticata</h3>
        <div class="form-group">
            <label>Email</label>
            <input type="email" class="form-control" v-madol="email" eplaceholder="Email" />


        </div>

        <button class="btn btn-primary btn-block">Invia</button>
    </form>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'forgot',
        data() {
            return {
                email:''
            }
        },
        methods: {
            async handleSubmit() {
                const response = await axios.post('forgot', {
                    email: this.email
                });
                console.log(response);
            }
        }

    }
</script>