import { render, staticRenderFns } from "./EditUserBoForm.vue?vue&type=template&id=3b380e2c"
import script from "./EditUserBoForm.vue?vue&type=script&lang=js"
export * from "./EditUserBoForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports