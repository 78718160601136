import Vue from 'vue'
import router from 'vue-router'
import Home from "@/components/Home.vue";
import Login from "@/components/Login/Login.vue";
import Register from "@/components/Login/Register.vue";
import Forgot from "@/components/Login/Forgot.vue";
import Reset from "@/components/Login/Reset.vue";

import CreateUserBoForm from "@/components/Users/CreateUserBoForm.vue";
import DeleteUserBoModal from "@/components/Users/DeleteUserBoModal.vue";
import EditUserBoForm from "@/components/Users/EditUserBoForm.vue";
import UserBoOverview from "@/components/Users/UserBoOverview.vue";
import UserBoTable from "@/components/Users/UserBoTable.vue";

Vue.use(router);

export default new router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/forgot',
            name: 'Forgot',
            component: Forgot
        },
        {
            path: '/reset/:token',
            name: 'Reset',
            component: Reset
        },
        {
            path: '/createUserBoForm',
            name: 'CreateUserBoForm',
            component: CreateUserBoForm
        }
        ,
        {
            path: '/deleteUserBoModal',
            name: 'DeleteUserBoModal',
            component: DeleteUserBoModal
        }
        ,
        {
            path: '/editUserBoForm',
            name: 'EditUserBoForm',
            component: EditUserBoForm
        }
        ,
        {
            path: '/userBoOverview',
            name: 'UserBoOverview',
            component: UserBoOverview
        }
        ,
        {
            path: '/userBoTable',
            name: 'UserBoTable',
            component: UserBoTable
        }
    ]


})