<template>
    <div>
        <b-row class="text-center">
            <b-col>
                <div class="filter-card p-3"
                     :class="{
                        'bg-active-filter': totalIsActive,
                        'bg-light': !totalIsActive,
                      }"
                     @click="totalUserIsActiveFilter">
                    <h6>Utenti totali</h6>
                    <h4>
                        <strong>{{ totalUser }}</strong>
                    </h4>
                </div>
            </b-col>
            <b-col>
                <div class="filter-card p-3"
                     :class="{'bg-active-filter': activeIsActive,'bg-light': !activeIsActive,}"
                     @click="activeUserIsActiveFilter">
                    <h6 class="text-secondary">Utenti Attivi</h6>
                    <h4>
                        <strong>{{ activeUser }}</strong>
                    </h4>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "UserOverview",
        props: {
            totalUser: Number,
            activeUser: Number,
        },
        data() {
            return {
                totalIsActive: true,
                activeIsActive: false,
            };
        },
        methods: {
            totalUserIsActiveFilter() {
                this.totalIsActive = true;
                this.activeIsActive = false;
                this.$emit("totalUserIsActive");
            },
            activeUserIsActiveFilter() {
                this.totalIsActive = false;
                this.activeIsActive = true;
                this.$emit("activeUserIsActive");
            },
        },
    };
</script>

<style>
    .filter-card:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .bg-active-filter {
        background-color: #e9f1fe;
        color: #074297;
    }
</style>