import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    user: null,
    loading: false,
    token: null
};

const store = new Vuex.Store({
    state,

    getters: {
        user: (state) => {
            return state.user;
        },
        isLoading(state) {
            return !!state.loading;
        },
        getToken(state) {
            return state.token;
        },
    },

    actions: {
        user(context, user) {
            context.commit('user', user)
        },
        setToken(context, token) {
            context.commit('token', token)
        },
        setLoading(context, payload) {
            if(payload) {
                context.commit("ENABLE_LOADING");
            } else {
                context.commit("DISABLE_LOADING");
            }
        }
    },

    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        user(state, user) {
            state.user = user
        },
        ENABLE_LOADING(state) {
            state.loading = true;
        },
        DISABLE_LOADING(state) {
            state.loading = false;  
        }
    }
})

export default store