<template>
    <b-form class="mt-3">
        <b-row>
            <b-alert v-model="showAlert" variant="success" dismissible>
                {{ alertMessage }}
            </b-alert>
        </b-row>
        <b-row>
            <b-row>
                <h4 class="text-secondary">Utente</h4>
            </b-row>
            <b-col cols="6">
                <b-form-group id="name_" label="Nome" label-for="name_">
                    <b-form-input id="name_"
                                  type="text"
                                  placeholder="Nome"
                                  v-model="userBo_.name"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group id="surname_" label="Cognome" label-for="surname_">
                    <b-form-input id="surname_"
                                  type="text"
                                  placeholder="Cognome"
                                  v-model="userBo_.surname"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group id="email_" label="Email - User Name" label-for="email_">
                    <b-form-input id="email_"
                                  type="text"
                                  placeholder="Email Address (User Name)"
                                  v-model="userBo_.email"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group id="selectedRole" label="Ruolo" label-for="selectedRole">
                    <b-form-select v-model="userBo_.idRole" :options="optionsRole"></b-form-select>
                    <label style="color: red;" v-if="!roleIsValid">  Selezionare un ruolo </label>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <h4 class="text-secondary">Scegli password</h4>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-group id="password_" label="Password" label-for="password_">
                    <b-form-input id="password_"
                                  type="password"
                                  placeholder="Password"
                                  v-model="userBo_.password"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-group id="passwordConfirm" label="Conferma Password" label-for="passwordConfirm">
                    <b-form-input id="passwordConfirm"
                                  type="password"
                                  placeholder="Conferma Password"
                                  v-model="passwordConfirm"></b-form-input>
                    <label style="color: red;" v-if="!passwordMatch">Password non corrispondente</label>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-form-checkbox id="deleted"
                             v-model="userBo_.deleted"
                             name="deleted"
                             value="0"
                             unchecked-value="0">
                Utente Attivo
            </b-form-checkbox>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="3">
                <b-button variant="primary" :disabled="!formIsValid" class="px-5" @click="addNewUser">Salva</b-button>
            </b-col>
            <b-col>
                <b-button variant="warning" @click="triggerClose">Chiudi</b-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateUserBoForm",
  data() {
    return {
        userBo_: this.initForm(),
        selectedRole: null,
        optionsRole: [
            { value: null, text: 'Assegnare un ruolo' },
            { value: "2", text: 'User' },
            { value: "3", text: 'SuperUser' }
        ],
        showAlert: false,
        alertMessage: "",
        passwordConfirm :""
    };
  },
    computed: {
        emailIsValid() {
            if (!this.userBo_.email) {
                return false;
            } else if (!this.validEmail(this.userBo_.email)) {
                return false;
            } else {
                return true;
            }
        },
        passwordIsValid() {
            if (!this.userBo_) return false;
            return (!!this.userBo_.password) || (this.userBo_.password.lenght > 4);
        },
        nameIsValid() {
            return (!!this.userBo_.name) || (!!this.userBo_.surname);
        },
        roleIsValid() {
            return (!!this.userBo_.idRole) || (this.userBo_.idRole>0);
        },
        formIsValid() {
            return this.emailIsValid && this.passwordIsValid && this.nameIsValid && this.roleIsValid && this.passwordMatch
        },
        passwordMatch() {
            return (this.userBo_.password === this.passwordConfirm);
        },
    },
  methods: {
    triggerClose() {
      this.$emit("closeCreateModal");
      },
      initForm() {
          return {
              deleted:0,
              name_: "",
              surname_: "",
              email_: "",
              idRole:0,
              password_: "",
              password_confirmation: "",
              
          }
      },
      addNewUser() {
          console.log(this.userBo_);
      axios
          .post(`/api/Account/RegisterUserBo/`, this.userBo_)
        .then((response) => {
            console.log(response.data);
            var token = response.data.authenticationToken;
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          this.$emit("closeCreateModal");
          this.$emit("reloadDataTable");
          this.$emit("showSuccessAlert");
        })
        .catch((error) => {
            console.log(error);
            this.showAlert = true;
            this.alertMessage = "Errore nella compilazione dei campi o nome utente (email) esistente. Salvataggio non riuscito"
        });
      },
      validEmail: function (email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
      }
  },
};
</script>