<template>
    <div id="app" :class="[isLoading ? 'loading' : '']">
        <b-row>
            <b-alert v-model="showSuccessAlert" variant="success" dismissible>
                {{ alertMessage }}
            </b-alert>
        </b-row>
        <img alt="Teseco Bonifiche" src="./assets/logo.png">

        <navbar />
        <div>
            <div class="container">
                <router-view />
            </div>
        </div>

    </div>
</template>
<script>
    import Navbar from "@/components/Navbar";
    import { mapGetters } from 'vuex';



    export default {
        name: 'App',
        data() {
            return {
                showSuccessAlert: false,
                alertMessage: ""
            }
        },
        methods: {
            sessionExpired() {
                this.alertMessage = "Sessione scaduta, � necessario un nuovo login."
                this.showSuccessAlert = true;

            }
        },

        components: {
            Navbar,
        },
        computed: {
            ...mapGetters(['user']),
            ...mapGetters(['isLoading'])
        }
    }
</script>

<style>
    .form-signin {
        width: 100%;
        max-width: 400px;
        padding: 15px;
        margin: 5rem auto 0;
    }
</style>