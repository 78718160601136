<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
         
          <router-link class="navbar-brand" :to="{name:'Home'}">Maintenance - Services</router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#nscontent" aria-controls="nscontent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="nscontent">
              <ul class="navbar-nav ms-auto mb-5 mb-lg-0">
                  <template v-if="user && user.idrole==1">
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'CustomerTable'}"><strong>Customer</strong></router-link>
                      </li>
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'UserBoTable'}"><strong>Utenti portale</strong></router-link>
                      </li>
                  </template>
                  <template v-if="user && (user.idrole==1 || user.idrole==3)">
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'CampaignTable'}"><strong>Campagne</strong></router-link>
                      </li>
                  </template>
                  <template v-if="user">
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'ImportFileTable'}">Gestione File</router-link>
                      </li>
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'UserTable'}">Utenti</router-link>
                      </li>

                      <li class="nav-item">
                          <a class="nav-link" href="" @click.prevent="logout">Logout</a>
                      </li>
                  </template>
                  <template v-if="!user">
                      <li class="nav-item">
                          <router-link class="nav-link" :to="{name:'Login'}">Login</router-link>
                      </li>
                      <!--<li class="nav-item">
         <router-link class="nav-link" :to="{name:'Register'}">Register</router-link>
        </li>-->
                  </template>
              </ul>
          </div>
      </div>
  </nav>
</template>

<script>
    import { mapGetters } from 'vuex';
    

export default {
        name: "Navbar",
        computed: {
            ...mapGetters(['user'])            
        },
        methods: {
            logout() {
                localStorage.removeItem('token')
                this.$store.dispatch('user', null);
                this.$router.push('/')
            }
        }
}
</script>

<style scoped>
    
</style>