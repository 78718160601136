<template>
    <b-form class="mt-3">
        <b-row>
            <b-row>
                <h4 class="text-secondary">Utente</h4>
            </b-row>
            <b-col cols="6">
                <b-form-group id="name" label="Nome" label-for="name">
                    <b-form-input id="name"
                                  type="text"
                                  placeholder="Nome"
                                  v-model="userBo.name"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group id="surname" label="Cognome" label-for="surname">
                    <b-form-input id="surname"
                                  type="text"
                                  placeholder="Cognome"
                                  v-model="userBo.surname"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group id="email" label="Email" label-for="email">
                    <b-form-input :disabled=true 
                                  id="email"
                                  type="text"
                                  placeholder="Email"
                                  v-model="userBo.email"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group id="selectedRole" label="Ruolo" label-for="selectedRole">
                    <b-form-select v-model="userBo.idrole" :options="optionsRole"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="6">
                <b-form-checkbox id="deleted"
                                 v-model="userBo.deleted"
                                 name="deleted"
                                 value="active"
                                 unchecked-value="deleted">
                    Cancellato
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="3">
                <b-button variant="primary" class="px-5" @click="updateUser">Aggiorna Utente</b-button>
            </b-col>
            <b-col>
                <b-button variant="warning" @click="triggerClose">Chiudi</b-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import axios from "axios";

export default {
  name: "EditUserModal",
  props: {
      userId: Number,
  },
  data() {
    return {
        userBo: {},
        selectedRole: null,
        optionsRole: [
            { value: null, text: 'Assegnare un ruolo' },
            { value: "1", text: 'Admin' },
            { value: "2", text: 'User' },
            { value: "3", text: 'SuperUser' }
        ],
    };
  },
  mounted() {
    this.getUserByID();
  },
  methods: {
    triggerClose() {
      this.$emit("closeEditModal");
    },
    getUserByID() {
      axios.get(`/api/UsersBoes/GetUserBoByID/${this.userId}`)
        .then((response) => {
            this.userBo = response.data;
            console.log(this.userBo);
        })
        .catch((error) => {
          console.log(error);
        });
    },
      updateUser() {
          console.log(this.userBo);
      axios.post(`/api/UsersBoes/UpdateUserBo`,this.userBo)
        .then((response) => {
          console.log(response.data);
          this.$emit("closeEditModal");
          this.$emit("reloadDataTable");
          this.$emit("showSuccessAlert");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>